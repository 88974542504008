import React from 'react'
import Iframe from 'react-iframe'


const HW2 = () => {

  return (
    <div>
    
    <Iframe url="https://editor.p5js.org/stanno/full/_T0eyGeT_"
    width="700px"
    height="542px"
    id=""
    className=""
    display="block"
    position="relative"
    allowFullScreen="true"
    />
    </div>
  )
}

export default HW2